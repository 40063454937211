import { useContext } from "react";
import UserContext from "./usercontext";

const useSession = () => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error(
      "Cannot use session as user is not defined. Check that useSession is called where user is available"
    );
  }

  return { user };
};

export default useSession;
