import React, { useState } from "react";
import useSession from "../../../firebase/useSession";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useFirebase } from "../../../firebase";
import DbUser from "../../../storage/user/dbuser";

const UserPage = () => {
  const { user } = useSession();
  const { firebase } = useFirebase();
  const [name, setName] = useState(user.name);
  const [edit, setedit] = useState(false);
  const [password, setpassword] = useState("");

  const updateProfile = (event: React.FormEvent<HTMLFormElement>) => {
    setedit(false);

    let dbUser = user as DbUser;
    dbUser.name = name;

    firebase
      .user(user.uid)
      .update({
        name: name
      })
      .then(() => {
        console.log("Update successful");
      })
      .catch(error => {
        console.error(error);
      });

    event.preventDefault();
  };

  const changePassword = (event: React.FormEvent<HTMLFormElement>) => {
    const fbUser = firebase.auth.currentUser;
    if (!fbUser) throw new Error("No current user active");

    fbUser
      .updatePassword(password)
      .then(() => {
        // Update successful.
        console.log("updated password!");
      })
      .catch((e: Error) => {
        console.error(e);
      });

    event.preventDefault();
  };

  return (
    <Container>
      <Row>
        <Col className="text-center mb-5">
          <h1>Lietotāja profils</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              changePassword(e)
            }
          >
            <Form.Group>
              <Form.Label>Vārds: </Form.Label>
              <input
                type="text"
                name="displayName"
                id=""
                value={name ? name : ""}
                readOnly={!edit}
                onChange={(e: any) => setName(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" disabled={!edit}>
              Save
            </Button>
            <Button disabled={edit} type="button" onClick={() => setedit(true)}>
              Edit
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mb-5">
          <h1>Paroles maiņa</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => updateProfile(e)}
          >
            <Form.Group>
              <Form.Label>Jauna parole: </Form.Label>
              <input
                type="password"
                name="passwordNew"
                value={password ? password : ""}
                onChange={(e: any) => setpassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit">Nomainīt paroli</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPage;
