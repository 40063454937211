import React from "react";
import { Col, Row, Container } from "react-bootstrap";

const Unauthorized = () => {
  return (
    <Container>
      <Row className="d-flex align-items-center justify-content-center vh-100">
        <Col className="text-center">
          <h1>Not authorized to access this page</h1>
        </Col>
      </Row>
    </Container>
  );
};

export default Unauthorized;
