import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import SheetMusic from "../../../storage/music/sheetmusic";
import { useFirebase } from "../../../firebase";

const UploadForm = () => {
  const { firebase } = useFirebase();
  const [author, setauthor] = useState("");
  const [title, settitle] = useState("");
  const [files, setfiles] = useState<File[]>([]);
  const [error, seterror] = useState<Error | null>(null);
  const [success, setsuccess] = useState<string | null>(null);

  const handleFiles = (files: FileList) => {
    let fileList: File[] = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
    }

    setfiles(fileList);
  };

  const isValid = author !== "" && title !== "" && files.length > 0;

  const formSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    seterror(null);
    setsuccess(null);
    console.log(files);

    const storageRef = firebase.storage.ref();

    files.forEach(file => {
      let metadata = {
        contentType: file.type
      };
      let fileRef = storageRef.child(`music/${file.name}`);

      try {
        var uploadTask = fileRef.put(file, metadata);

        uploadTask.on(
          firebase.storageChangedEvent,
          snapshot => {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.uploadState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.uploadState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
              default:
                console.log("default snapshot state", snapshot.state);
                break;
            }
          },
          e => {
            const error = e as firebase.FirebaseError;
            if (!error) {
              console.error(e);
              // seterror(e);
              return;
            }

            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                console.error(
                  "User doesn't have permission to access the object"
                );
                // seterror(error);
                break;
              case "storage/canceled":
                // User canceled the upload
                console.error("Upload cancelled by user");
                // seterror(error);
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                console.error("Unknown error");
                // seterror(error);
                break;

              default:
                console.log("test2");
                break;
            }
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
              console.log(`File available at: ${url}`);

              let musicRef = firebase.music().doc();

              let music: SheetMusic = {
                id: musicRef.id,
                downloadURL: url,
                author: author,
                name: file.name,
                title: title,
                size: file.size,
                added_on: firebase.getTimeStamp(new Date())
              };

              musicRef
                .set(music)
                .then(() => {
                  setsuccess("Successfully uploaded file!");
                  setauthor("");
                  settitle("");
                  setfiles([]);
                })
                .catch(error => seterror(error));
            });
          }
        );
      } catch (e) {
        console.error(e);
        return;
      }
    });

    event.preventDefault();
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formSubmit(e)}>
      <Form.Group controlId="uploadFormFile">
        <Form.Label>Izvēlēties failu</Form.Label>
        <Form.Control
          type="file"
          name="musicFile"
          onChange={(e: any) => handleFiles(e.target.files)}
        />
      </Form.Group>
      <Form.Group controlId="uploadFormAuthor">
        <Form.Label>Autors</Form.Label>
        <Form.Control
          type="text"
          name="musicAuthor"
          onChange={(e: any) => setauthor(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="uploadFormTitle">
        <Form.Label>Nosaukums</Form.Label>
        <Form.Control
          type="text"
          name="musicTitle"
          onChange={(e: any) => settitle(e.target.value)}
        />
      </Form.Group>

      <Button variant="primary" type="submit" disabled={!isValid}>
        Submit
      </Button>
      {success ? <h2>{success}</h2> : null}
      {error ? <h2>{error.message}</h2> : null}
    </Form>
  );
};

export default UploadForm;
