import React, { useState } from "react";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import useMusic from "../../../storage/music";
import UploadForm from "./uploadform";
import { Form } from "react-bootstrap";
import useSession from "../../../firebase/useSession";
import Roles from "../../../constants/roles";
import { Loading } from "../../../components";
import "./music.css";
import SheetMusic from "../../../storage/music/sheetmusic";
import { useFirebase } from "../../../firebase";

const MusicPage = () => {
  const { music, loading, error } = useMusic();
  const [upload, setupload] = useState(false);
  const [filter, setfilter] = useState("");
  const { user } = useSession();
  const { firebase } = useFirebase();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const isAdmin = user.roles.includes(Roles.admin);

  const onFeatured = (sheet: SheetMusic) => {
    console.log(sheet);

    let ref = firebase.musicSheet(sheet.id);
    ref.update({
      featured: !sheet.featured
    });
  };

  return (
    <Container>
      <Row>
        <Col className="text-center my-3">
          <h2>Notis</h2>
        </Col>
      </Row>

      {isAdmin ? (
        <Row>
          <Col className="text-center my-3">
            <Button onClick={() => setupload(prev => !prev)}>
              Augšuplādēt notis
            </Button>
          </Col>
        </Row>
      ) : null}

      {upload ? (
        <Row>
          <Col className="text-center my-3">
            <UploadForm />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col className="text-center my-3">
          <Form>
            <Form.Group controlId="uploadFormAuthor">
              <Form.Label>Filtrēt notis</Form.Label>
              <Form.Control
                type="text"
                name="filter"
                onChange={(e: any) => setfilter(e.target.value.toLowerCase())}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          <ListGroup>
            {music.map(sheet => {
              if (filter) {
                let toFilter = (sheet.title + sheet.author).toLowerCase();
                if (!toFilter.includes(filter)) return null;
              }

              if (isAdmin) {
                return (
                  <ListGroup.Item
                    // action
                    key={sheet.id}
                    // as="a"
                    // href={sheet.downloadURL}
                    // download
                    className="group-item"
                  >
                    {sheet.title} - {sheet.author}
                    <div>
                      <Button
                        as="a"
                        href={sheet.downloadURL}
                        download
                        className="mx-3"
                      >
                        Download
                      </Button>
                      {isAdmin ? (
                        <Button onClick={() => onFeatured(sheet)}>
                          {sheet.featured ? "-" : "+"}
                        </Button>
                      ) : null}
                    </div>
                  </ListGroup.Item>
                );
              }

              return (
                <ListGroup.Item
                  action
                  key={sheet.id}
                  as="a"
                  href={sheet.downloadURL}
                  download
                  className="group-item"
                >
                  {sheet.title} - {sheet.author}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default MusicPage;
