import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";
import DbUser from "../storage/user/dbuser";
import AppUser from "../components/appuser";
import Roles from "../constants/roles";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

class Firebase {
  public auth: firebase.auth.Auth;
  public firestore: firebase.firestore.Firestore;
  public storage: firebase.storage.Storage;
  public storageChangedEvent: string = firebase.storage.TaskEvent.STATE_CHANGED;
  public uploadState = firebase.storage.TaskState;
  public googleProvider: firebase.auth.GoogleAuthProvider;
  public facebookProvider: firebase.auth.FacebookAuthProvider;
  public perf: firebase.performance.Performance;

  public secondaryApp: firebase.app.App | undefined;
  public arrayUnion: (...elements: any[]) => firebase.firestore.FieldValue;
  public arrayRemove: (...elements: any[]) => firebase.firestore.FieldValue;
  public getTimeStamp: (date: Date) => firebase.firestore.Timestamp;

  constructor() {
    // Ugly hack so that I can use context without providing undefined type
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    if (!firebase.apps.find(f => f.name === "Secondary")) {
      this.secondaryApp = firebase.initializeApp(config, "Secondary");
    }

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.storage = firebase.storage();
    this.perf = firebase.performance();

    this.arrayUnion = firebase.firestore.FieldValue.arrayUnion;
    this.arrayRemove = firebase.firestore.FieldValue.arrayRemove;
    this.getTimeStamp = firebase.firestore.Timestamp.fromDate;

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
  }

  onAuthUserListener = (next: any, fallback: any) =>
    this.auth.onAuthStateChanged(fbUser => {
      if (fbUser) {
        //TODO: check if this should be unregistered somewhere
        this.user(fbUser.uid).onSnapshot(
          snap => {
            const dbUser = snap.data() as DbUser;
            if (!dbUser) {
              // Create user if not present in db
              let newUser: DbUser = {
                roles: [Roles.default]
              };

              this.user(fbUser.uid).set(newUser);
            }

            let appUser: AppUser = { ...fbUser, ...dbUser };
            next(appUser);
          },
          error => {
            console.error(error);
            fallback();
          }
        );
      } else {
        fallback();
      }
    });

  /*  Auth API  */
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);
  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: string): Promise<void> => {
    let user = this.auth.currentUser;
    if (user) {
      return user.updatePassword(password);
    }

    return Promise.resolve();
  };

  /*  Music Storage  */
  music = () => this.firestore.collection("music");
  musicSheet = (id: string) => this.music().doc(id);

  /*  User API  */
  user = (uid: string) => this.firestore.collection("users").doc(uid);
  users = () => this.firestore.collection("users");

  /* Projects API */
  projects = () => this.firestore.collection("projects");
  project = (id: string) => this.projects().doc(id);
}

export default Firebase;
