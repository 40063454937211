import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import useRouter from "use-react-router";
import "./signin.css";
import { useFirebase } from "../../firebase";
import Routes from "../../constants/routes";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<Error | null>(null);
  const { firebase } = useFirebase();

  const { history } = useRouter();

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    setError(null);

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(_ => {
        setEmail("");
        setPassword("");
        setError(null);
        history.push(Routes.admin);
      })
      .catch(error => {
        setError(error);
      });

    event.preventDefault();
  };

  const isInvalid = password === "" || email === "";

  return (
    <section>
      <Container>
        <Row className="d-flex align-items-center justify-content-center vh-100">
          <Col sm={9} md={7} lg={5} className="mx-auto">
            <Card className="styled-card">
              <Card.Title className="text-center card-title-styled">
                <h5>Sign In</h5>
              </Card.Title>
              <Card.Body className="card-body-styled">
                <Form onSubmit={submit} className="styled-form">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="form-group-styled"
                  >
                    <Form.Control
                      className="form-control-styled"
                      type="email"
                      placeholder="Email address"
                      onChange={(e: any) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="formBasicPassword"
                    className="form-group-styled"
                  >
                    <Form.Control
                      className="form-control-styled"
                      type="password"
                      placeholder="Password"
                      onChange={(e: any) => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button
                    className="form-button-styled"
                    variant="primary"
                    type="submit"
                    disabled={isInvalid}
                    block
                  >
                    Sign In
                  </Button>
                </Form>
                {error ? <div>{error.message}</div> : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignIn;
