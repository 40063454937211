import { useContext } from "react";
import FirebaseContext from "./context";

const useFirebase = () => {
    const firebase = useContext(FirebaseContext);
    if (!firebase) {
        throw new Error("Firebase not defined!");
    }

    return { firebase };
};

export default useFirebase;
