import React from "react";
import "./attendance.css";
import useSession from "../../../firebase/useSession";
import Roles from "../../../constants/roles";
import { Unauthorized } from "../../../components/admin";

const AttendancePage = () => {
  const { user } = useSession();
  const sheetUrl = process.env.REACT_APP_ATTENDANCE_SHEET_URL;

  if (!user.roles.includes(Roles.admin)) {
    return <Unauthorized />;
  }

  if (!sheetUrl) {
    return (
      <div>Something went wrong, please use google sheets directly...</div>
    );
  }

  let html = `<iframe class="google-sheet-frame" src="${sheetUrl}"></iframe>`;
  return (
    <div
      className="google-sheet-wrapper"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default AttendancePage;
