import React, { useState, useEffect } from "react";
import useRouter from "use-react-router";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem, Button, Navbar } from "react-bootstrap";
import useSession from "../../../firebase/useSession";
import { useFirebase } from "../../../firebase";
import Routes from "../../../constants/routes";
import "./sidebar.css";

import { FaBars } from "react-icons/fa";

import ReactSidebar from "react-sidebar";

const mql = window.matchMedia(`(min-width: 800px)`);

const Sidebar = ({ children }: { children?: React.ReactChild }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

  useEffect(() => {
    const queryChanged = () => {
      setSidebarDocked(mql.matches);
      setSidebarOpen(false);
    };

    mql.addListener(queryChanged);
    return () => {
      mql.removeListener(queryChanged);
    };
  }, []);

  return (
    <ReactSidebar
      sidebar={<SidebarContent />}
      open={sidebarOpen}
      onSetOpen={open => setSidebarOpen(open)}
      docked={sidebarDocked}
      sidebarClassName="react-sidebar"
    >
      {sidebarDocked ? null : (
        <Navbar>
          <Navbar.Brand>
            <button
              onClick={() => setSidebarOpen(true)}
              className="sidebar-button"
            >
              <FaBars />
            </button>
          </Navbar.Brand>
        </Navbar>
      )}
      {children}
    </ReactSidebar>
  );
};

const SidebarContent = () => {
  const { user } = useSession();
  const { history } = useRouter();
  const { firebase } = useFirebase();

  const onSignOut = () => {
    firebase.doSignOut().then(() => {
      history.push(Routes.landing);
    });
  };

  return (
    <div className="mx-3">
      <h1 className="text-center mx-3 my-3">
        {user.name ? user.name : user.email}
      </h1>
      <ListGroup className="text-center">
        <ListGroupItem as={Link} to={Routes.admin}>
          Admin
        </ListGroupItem>
        <ListGroupItem as={Link} to={Routes.adminRoutes.music}>
          Notis
        </ListGroupItem>
        <ListGroupItem as={Link} to={Routes.adminRoutes.projects}>
          Projekti
        </ListGroupItem>
        <ListGroupItem as={Link} to={Routes.adminRoutes.user}>
          Lietotājs
        </ListGroupItem>
        <ListGroupItem as={Link} to={Routes.adminRoutes.attendance}>
          Apmeklējums
        </ListGroupItem>
        <ListGroupItem as={Link} to={Routes.adminRoutes.calendar}>
          Kalendārs
        </ListGroupItem>
        <Button onClick={() => onSignOut()}>Sign Out</Button>
        <hr className="ruler my-4" />
        <ListGroupItem as={Link} to={Routes.landing}>
          Home
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default Sidebar;
