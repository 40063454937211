import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import useSession from "../../../firebase/useSession";
import Roles from "../../../constants/roles";
import { Unauthorized } from "../../../components/admin";
import { Form } from "react-bootstrap";
import { useFirebase } from "../../../firebase";
import Project from "../../../storage/projects/project";

const ProjectsPage = () => {
  const { user } = useSession();
  const { firebase } = useFirebase();
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [internal, setinternal] = useState(false);
  const [success, setsuccess] = useState<string | null>(null);
  const [error, seterror] = useState<Error | null>(null);

  if (!user.roles.includes(Roles.admin)) {
    return <Unauthorized />;
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    seterror(null);
    setsuccess(null);
    event.preventDefault();

    let projectRef = firebase.projects().doc();

    let project: Project = {
      id: projectRef.id,
      title: title,
      description: description,
      added_on: firebase.getTimeStamp(new Date()),
      internal: internal,
    };

    projectRef
      .set(project)
      .then(() => {
        setsuccess("Successfully added new project!");
        settitle("");
        setdescription("");
        setinternal(false);
      })
      .catch((error) => seterror(error));
  };

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h1>Allow adding projects from here</h1>
        </Col>
      </Row>
      <Row className="d-flex align-items-center justify-content-center">
        <Col>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => onSubmit(e)}>
            <Form.Group>
              <Form.Label>Projekta nosaukums</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e: any) => settitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Projekta apraksts</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={description}
                onChange={(e: any) => setdescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                checked={internal}
                label="Is this internal?"
                onChange={() => setinternal((prev) => !prev)}
              />
            </Form.Group>
            <Button type="submit">Pievienot pasākumu</Button>
            {success ? <h1>{success}</h1> : null}
            {error ? <h1>{error.message}</h1> : null}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectsPage;
