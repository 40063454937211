import React, { useState } from "react";
import { useFirebase } from "../firebase";
import AppUser from "../components/appuser";

interface IAuthState {
  initializing: boolean;
  user: AppUser | null;
}

const useAuth = () => {
  const { firebase } = useFirebase();

  const [state, setState] = useState<IAuthState>(() => {
    // const user = firebase.auth.currentUser;
    const user = null;
    return {
      initializing: !user,
      user: user
    };
  });

  const onAutenticated = (user: AppUser) => {
    setState({ initializing: false, user });
  };
  const onNoUser = () => {
    setState({ initializing: false, user: null });
  };

  React.useEffect(() => {
    // const unsubscribe = firebase.auth.onAuthStateChanged(onChange);
    const unsubscribe = firebase.onAuthUserListener(onAutenticated, onNoUser);

    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, [firebase]);

  return state;
};

export default useAuth;
