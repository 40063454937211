import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotFoundPage, AdminPage } from "./pages";
import Routes from "./constants/routes";
import useAuth from "./firebase/useAuth";
import UserContext from "./firebase/usercontext";
import { SignIn, Loading } from "./components";
import UnderConstructionPage from "./pages/underconstruction";

const App: React.FC = () => {
  const { user, initializing } = useAuth();

  if (initializing) {
    return <Loading />;
  }

  if (!user) {
    return (
      <div id="App">
        <UserContext.Provider value={null}>
          <Router>
            <NonAuthRoutes />
          </Router>
        </UserContext.Provider>
      </div>
    );
  }

  return (
    <div id="App">
      <UserContext.Provider value={user}>
        <Router>
          <AppRoutes />
        </Router>
      </UserContext.Provider>
    </div>
  );
};

const NonAuthRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        {/* <Route path={Routes.landing} exact component={UnderConstructionPage} /> */}
        <Route path={Routes.landing} exact component={() => {
          window.location.replace(
            // "https://laimavikmane.typeform.com/to/WFiP5C" // laimai beidzās vietas typeform, tāpēc tagad ir jauns links
            // "https://2ed48f8s33e.typeform.com/to/xGIf9qGp"
            "https://docs.google.com/forms/d/e/1FAIpQLSf_ifMzpGUw4wt8MSsQuxlkf0n0rQqaLHjtSZhLNpl6oycSZA/viewform?usp=sf_link"
          );
          return null;
        }} />

        {/* TODO: build a real landing page and use that, under construction page by EK */}
        {/* <Route path={Routes.landing} exact component={LandingPage} /> */}

        <Route path={Routes.admin} component={SignIn} />

        {/* TODO: REMOVE THIS. Workaround for  */}
        <CommonRoutes />

        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
};

const CommonRoutes = () => {
  return (
    <>
      <Route
        path="/kalendars"
        component={() => {
          window.location.replace(
            "https://calendar.google.com/calendar?cid=cmtpNmJkbzlnajhnOGttMGo5NzBjaHZxNGtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"
          );
          return null;
        }}
      />
      <Route
        path="/notis"
        component={() => {
          window.location.replace(
            "https://drive.google.com/drive/folders/1YNa1Q9BzfFLmWQBKGyTkaGkXNbqyikC9"
          );
          return null;
        }}
      />
      <Route
        path="/piesakos"
        component={() => {
          window.location.replace(
            "https://laimavikmane.typeform.com/to/WFiP5C"
          );
          return null;
        }}
      />
    </>
  );
};

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path={Routes.landing} exact component={UnderConstructionPage} />

        {/* TODO: build a real landing page and use that, under construction page by EK */}
        {/* <Route path={Routes.landing} exact component={LandingPage} /> */}

        <Route path={Routes.admin} component={AdminPage} />

        {/* TODO: REMOVE THIS. Workaround for  */}
        <CommonRoutes />

        <Route component={NotFoundPage} />
      </Switch>
    </React.Fragment>
  );
};

export default App;
