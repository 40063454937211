import React from "react";
import { Route, Switch } from "react-router";
import Routes from "../../constants/routes";
import { MusicPage, NotFoundPage } from "..";
import "./admin.css";
import AdminHomePage from "./home";
import { Sidebar } from "../../components/admin";
import UserPage from "./user";
import ProjectsPage from "./projects";
import AttendancePage from "./attendance";
import CalendarPage from "./calendar";

const AdminPage = () => {
  return (
    <React.Fragment>
      <Sidebar>
        <React.Fragment>
          <Switch>
            <Route path={Routes.admin} exact component={AdminHomePage} />
            <Route path={Routes.adminRoutes.music} component={MusicPage} />
            <Route path={Routes.adminRoutes.user} component={UserPage} />
            <Route
              path={Routes.adminRoutes.projects}
              component={ProjectsPage}
            />

            <Route
              path={Routes.adminRoutes.attendance}
              component={AttendancePage}
            />

            <Route
              path={Routes.adminRoutes.calendar}
              component={CalendarPage}
            />

            <Route component={NotFoundPage} />
          </Switch>
        </React.Fragment>
      </Sidebar>
    </React.Fragment>
  );
};

export default AdminPage;
