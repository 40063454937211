import React from "react";
import Tilt from "react-vanilla-tilt";
import square from "./square.svg";
import title from "./title.svg";
// import "./underConstruction.scss";

const UnderConstructionPage = () => {
  return (
    <div className="page-container">
      <Tilt className="logo" style={{}}>
        <div className="logo">
          <div className="logo-text"></div>
        </div>
        <img src={square} className="logo" alt="Ogle logo" />
        <img src={title} className="logo-text" alt="Ogle logo" />
      </Tilt>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/korisOgle/"
          >
            Jaunumi
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://m.me/korisOgle"
          >
            Sazinies
          </a>
        </li>
      </ul>
      {/* <ul>
        <li>
          <a target="_blank" href="https://laimavikmane.typeform.com/to/WFiP5C">
            Uzņemšana
          </a>
        </li>
      </ul> */}
    </div>
  );
};

export default UnderConstructionPage;
