import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import useMusic from "../../../storage/music";

const AdminHomePage = () => {
  const { music, loading, error } = useMusic();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error(error);
    return null;
  }

  return (
    <Container>
      <Row className="my-5">
        <Col className="text-center">
          <h1>This is admin panel home page</h1>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <h1>Aktuālās notis</h1>
          <ListGroup>
            {music.map(sheet => {
              if (!sheet.featured) {
                return null;
              }

              return (
                <ListGroup.Item
                  action
                  key={sheet.id}
                  as="a"
                  href={sheet.downloadURL}
                  download
                >
                  {sheet.title} - {sheet.author}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminHomePage;
