import React from "react";
import "./calendar.css";

const CalendarPage = () => {
  const calendarUrl = process.env.REACT_APP_CALENDAR_URL;

  if (!calendarUrl) {
    return (
      <div>Something went wrong, please use google calendar directly...</div>
    );
  }

  let html = `<iframe class="google-calendar-frame" src="${calendarUrl}" frameborder="0" scrolling="no"></iframe>`;
  return (
    <div
      className="google-calendar-wrapper"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default CalendarPage;
