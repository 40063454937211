const Routes = {
  landing: "/",
  projects: "/projects",
  admin: "/admin",
  adminRoutes: {
    music: "/admin/music",
    user: "/admin/user",
    projects: "/admin/projects",
    attendance: "/admin/attendance",
    calendar: "/admin/calendar"
  }
};

export default Routes;
